import { AuthContext } from "context/AuthContext";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { Secciones } from "utiles/Constantes";

export const isAuthenticated = (user) => !!user?.id;

export const isAllowed = (user, rights) => {
  if (!isAuthenticated(user)) return false;

  if (!rights) return false;
  if (Array.isArray(rights))
    return rights.some((right) => user.rights.includes(right));
  else
    return user.rights.includes(rights)
};

export const hasRole = (user, roles) => {
  if (!isAuthenticated(user)) return false;

  if (Array.isArray(roles))
    return roles.some((role) => user.roles.some( r => r.cod_interno === role))
  else
    return roles && user.roles.some( r => r.cod_interno === roles);
};

export const useAllowed = () => {
  const [secciones, setSecciones] = useState([]);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    let lista = Secciones.filter((x) => isAllowed(user, x.Seccion.Codigo));

    let result = [];
    for (let s of lista) {
      result.push({
        Seccion: s.Seccion,
        Items: s.Items.filter((x) => isAllowed(user, x.Item.Codigo)),
      });
    }
    result = result.filter((x) => x.Items.length > 0);

    setSecciones(result);
    return () => { }
  }, [user]);
  return secciones;
};


export const useCurrentAllowed = () => {
  const { user } = useContext(AuthContext)
  const secciones = useAllowed();

  let currentSecciones = secciones.filter(x => window.location.pathname.includes(x.Seccion.Url));

  if (currentSecciones.length > 0) {
    let currentItems = currentSecciones[0].Items.filter(x => window.location.pathname.includes(x.Item.Url))
    if (currentItems.length > 0) {
      return {
        Agrega: isAllowed(user, currentItems[0].Item.Agregar),
        Edita: isAllowed(user, currentItems[0].Item.Editar),
        Elimina: isAllowed(user, currentItems[0].Item.Eliminar),
        ...currentItems[0].Item
      };
    }
  }
};