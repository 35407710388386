import React from 'react';
import { Button, Nav, } from 'react-bootstrap';
import { Toolbar } from 'react-data-grid-addons';
import { FaFilter, FaPlusCircle, FaSync } from 'react-icons/fa';


class CustomToolbar extends Toolbar {

    renderAddRowButton = () => {
        if (this.props.onAddRow && this.props?.acciones.Agrega) {
            return (<Button size={this.props.isMobile?'sm':''} variant="outline-dark" onClick={this.onAddRow}>
                <FaPlusCircle /> {!this.props.isMobile && "Agregar"}
            </Button>)
        }
    };

    renderToggleFilterButton = () => {
        if (this.props.enableFilter) {
            return (<Button size={this.props.isMobile?'sm':''} variant="outline-dark" onClick={this.props.onToggleFilter}>
                <FaFilter/> {!this.props.isMobile && "Filtrar"}
            </Button>);
        }
    };

    renderReloadButton = () => {
        if (this.props.onReloadClick) {
            return (<Button size={this.props.isMobile?'sm':''} variant="outline-dark" onClick={this.props.onReloadClick}>
                <FaSync/> {!this.props.isMobile && "Recargar"}
            </Button>);
        }
    };
    render() {
       
    
        return (
            <Nav style={{margin: 2}} className="justify-content-end">
                {this.props.children}
                {this.renderReloadButton()}
                {this.renderToggleFilterButton()}
                {this.renderAddRowButton()}
            </Nav>);
    }
}

export default CustomToolbar;