import { AuthContext } from "context/AuthContext";
import { useAllowed } from "hooks/Auth";
import { useIsMobile } from "hooks/DeviceUtils";
import React, { useContext, useState } from "react";
import { FaHome, FaUser } from "react-icons/fa";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useHistory } from "react-router-dom";



const Sidebar = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const { user } = useContext(AuthContext);
  const { isMobile } = useIsMobile()
  
  const secciones = useAllowed();
  let history = useHistory();


  const estaActivo = (url) => {
    let activo = currentUrl.includes(url);
    return activo;
  };

  const handleClick = (e, url) => {
    if (e) e.preventDefault();
    history.push(url);
    handleToggleSidebar(false);
    setCurrentUrl(window.location.pathname);
  }

  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={(collapsed && !isMobile)}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    > {!collapsed && <>
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >

          {!!user && <>
            <FaUser />
            {" "}{user.name}<br />
            <small>
              {user.email}<br />  <br />

            </small> {(user.roles && user.roles.length > 0) && <>.:: {user.roles[0].nombre} ::.</>}
          </>}
        </div>
      </SidebarHeader>
    </>}
      <SidebarContent>
        {!collapsed &&
          <Menu iconShape="circle">
            <MenuItem suffix={<span className="badge red"></span>}>
              <a href="/" onClick={(e) => handleClick(e, '/')}><FaHome /> Inicio</a>
            </MenuItem>
          </Menu>
        }
        <Menu iconShape="circle">
          {secciones.map((s) => (
            <SubMenu
              icon={s.Seccion?.Icon}
              key={s.Seccion.Url}
              suffix={<span className="badge yellow">{s.Seccion.Suffix}</span>}
              title={s.Seccion.Nombre}
              defaultOpen={estaActivo(s.Seccion.Url)}
            >
              {s.Items.map((x) => (
                <MenuItem
                  key={x.Item.Url}
                  active={estaActivo(x.Item.Url)}>
                  {x.Item?.Icon}
                  {"  "}
                  <a href={x.Item.Url} onClick={(e) => handleClick(e, x.Item.Url)}>{x.Item.Nombre}</a>
                </MenuItem>
              ))}
            </SubMenu>
          ))}
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >

        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
